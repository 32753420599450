@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "mandhor";
    src: url("../src//assets//mandhor.otf");
}


@layer base {
    html {
      font-family: "Poppins", system-ui, sans-serif;
    }
  }
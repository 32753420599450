.card1 {
  background-color: transparent;

  color: white;

  display: flex;
  flex-direction: column;

  justify-content: center;
  height: 80vh;
}

/* Here Is The CSS For The Background*/

/* An Overlay To Dim The Image And Make Text Readable*/
#color-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 80vh;
  background-image: linear-gradient(to bottom right, #34495e, #2c3e50);
  opacity: 0.7;
  z-index: -1;
}

/* Setup For An Image That Fits On Most Screens And Resizes*/
.with-bg-size {
  background-image: url("/src/assets/dynamic/1.png");
  width: 100%;
  height: 80vh;
  background-position: center;
  background-size: cover;

  -webkit-animation: back 10s infinite;
  /* Safari 4+ */
  -moz-animation: back 10s infinite;
  /* Fx 5+ */
  -o-animation: back 10s infinite;
  /* Opera 12+ */
  animation: back 10s infinite;
  /* IE 10+, Fx 29+ */
}

/* The Keyframes*/
@keyframes back {
  33.33% {
    background-image: url("/src/assets/dynamic/3.png");
  }

  66.66% {
    background-image: url("/src/assets/dynamic/2.png");
  }

  /* Animation Does Not End At 100% Because It Makes The Images Transition In/Out Better For Some Reason*/
}
